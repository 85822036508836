import React, {useState, useEffect} from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';
import { mainurl, imgurl } from '../../Mainurl';

import { Row, Col, Card, CardHeader, CardBody, FormGroup, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';
import {FaEye, FaEdit, FaPlusCircle, FaClipboardList} from 'react-icons/fa';

const CropMaster = () => {
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [cropTypeOpt, setCropTypeOpt] = useState('');
    const [cropType, setCropType] = useState('');

    useEffect(()=>{
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": sessionStorage.getItem('user_id'), "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_type_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_type_id}> {row.crop_type_name}</option>
                    )
                });

                setCropTypeOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    },[])

    const crop_list = () => {
        setTableData('');
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": sessionStorage.getItem('user_id'), "crop_type_id": cropType, "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_master_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    
                    return(
                        {
                            sl: index + 1 ,
                            name: row.crop_name,
                            name_h: row.crop_name_ne,
                            crop_img: <img className='p-1' src={imgurl + row.photo} height="50px" alt="crop" />,
                            status: <div className='text-primary'>Active</div>,
                        }
                    )
                });

                setTableData(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    return(
        <>
            <Header />
            <Page title='Crop Master' breadcrumbs='Home / Crop Master'>
                <Row className='p-3'>
                    <Col xs={12}>
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                    <Col sm={3} className="mb-2">
                                        <Label for="type"> Crop Type </Label>
                                        <Input id="type" bsSize="sm" type="select" onChange={(e)=>setCropType(e.target.value)}>
                                            <option value="0"> --Select Crop Type--</option>
                                            {cropTypeOpt}
                                        </Input>
                                    </Col>
                                    <Col md={2}>
                                        <Button className='text-white mt-4' color='primary' size="sm" block onClick={crop_list}> <FaClipboardList className='me-1' size={20} /> Get List</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={{offset: 2, order: 2, size: 8}}>
                        <Card className='' color="primary" outline>
                            <CardBody>
                            <DataTable
                                title=""
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                    name: '#',
                                    selector: row=>row.sl,
                                    sortable: true,
                                    width: '80px'
                                    },
                                    {
                                    name: 'Name',
                                    selector: row=>row.name,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Name Hindi',
                                    selector: row=>row.name_h,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Image',
                                    selector: row=>row.crop_img,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Status',
                                    selector: row=>row.status,
                                    sortable: false,
                                    },
                                ]}
                                data={tableData}
                                highlightOnHover
                                progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>

                </Row>

            </Page>
            <Footer />

        </>
    )
}

export default CropMaster;