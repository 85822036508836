import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';
import { mainurl } from '../../Mainurl';

import TraceabilityDetail from '../../component/utility/farmActivity/TraceabilityDetail';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';
import { FaEye, FaSearch} from 'react-icons/fa';


const Traceability = () => {
    const [mode, setMode] = useState("1");
    const [loading, setLoading] = useState(false);
    const [cropTypeOpt, setCropTypeOpt] = useState('');
    const [cropType, setCropType] = useState('');
    const [cropOpt, setCropOpt] = useState('');
    const [crop, setCrop] = useState('');
    const [cropVarietyOpt, setCropVarietyOpt] = useState('');
    const [cropVariety, setCropVariety] = useState('');
    
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [block, setBlock] = useState('');
    
    const [stateOpt, setStateOpt] = useState('');
    const [districtOpt, setDistrictOpt] = useState('');
    const [blockOpt, setBlockOpt] = useState('');


    const [farmerTable, setFarmerTable] = useState('');

    const [traceDetail, setTraceDetail] = useState('');

    const get_main_list = (e) => {
        e.preventDefault();
        farmer_list_handler();
    }

    useEffect(()=>{
        get_crop_type();
        get_state_list();
    },[])

    const farmer_list_handler = () => {
        setLoading(true);
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": sessionStorage.getItem('user_id'), "crop_variety_code": cropVariety, "state_code": state, "dist_code": district, "block_code": block,  "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Traceability/trace_view_web',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
        .then(function (response) {
            let list = response.data.map((row, index) => {
                return {
                    sl: index + 1,
                    state: row.state_name,
                    district: row.dist_name,
                    block: row.block_name,
                    panchayat: row.pan_id,
                    village: row.vill_name,
                    name: row.f_name +" ("+ row.batch_no +")",
                    date: row.pop_start_date.split(" ")[0].split('-').reverse().join('-'),
                    action: <FaEye className='me-3 cursor' color="#FFC000" size={20} onClick={()=>traceability_detail_handler(row)} />
   
                }
            });

            setFarmerTable(list);
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
            //notify();
        }); 
        
    }

    const get_crop_type = () => {
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": sessionStorage.getItem('user_id'), "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_type_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_type_id}> {row.crop_type_name}</option>
                    )
                });

                setCropTypeOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_crop_list = (e) => {
        setCropOpt('');
        setCrop("0");
        setCropType(e.target.value);
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": sessionStorage.getItem('user_id'), "crop_type_id": e.target.value, "lang_id": 1}];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_master_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_code}> {row.crop_name}</option>
                    )
                });

                setCropOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const crop_variety_list = (e) => {
        setCrop(e.target.value);
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": sessionStorage.getItem('user_id'), "crop_code": e.target.value, "lang_id": 1}]
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_variety_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_variety_code}> {row.crop_variety_name}</option>
                    )
                });

                setCropVarietyOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_state_list = (e) => {
        
        let postData = [{"country_code": "1"}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_state_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.state_code}> {row.state_name}</option>
                    )
                });

                setStateOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_dist_list = (e) => {
        setState(e.target.value);
        let postData = [{"state_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_dist_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.district_code}> {row.district_name}</option>
                    )
                });

                setDistrictOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_block_list = (e) => {
        setDistrict(e.target.value);
        let postData = [{"dist_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.block_code}> {row.block_name}</option>
                    )
                });

                setBlockOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const traceability_detail_handler = (row) => {
        setTraceDetail(row);
        setMode("3");
    }

    const back_handler = () => {
        setMode("1");
    }


    return (
        <>
            <Header />
            <Page title='Traceability' breadcrumbs='Home / Traceability'>
                <Row className='p-3'>
                    {mode === "1" &&
                        <Col xs={12}>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Form onSubmit={get_main_list}>
                                        <Row>
                                            <Col sm={3} className="mb-2">
                                                <Label for="type"> Crop Type </Label>
                                                <Input id="type" bsSize="sm" type="select" value={cropType} onChange={get_crop_list}>
                                                    <option value="0"> --Select Crop Type--</option>
                                                    {cropTypeOpt}
                                                </Input>
                                            </Col>
                                            <Col sm={3} className="mb-2">
                                                <Label for="crop"> Crop </Label>
                                                <Input id="crop" bsSize="sm" type="select" value={crop} onChange={crop_variety_list}>
                                                    <option value="0"> --Select Crop --</option>
                                                    {cropOpt}
                                                </Input>
                                            </Col>
                                            <Col sm={3} className="mb-2">
                                                <Label for="cropVariety"> Crop Variety </Label>
                                                <Input id="cropVariety" bsSize="sm" type="select" value={cropVariety} onChange={(e)=>setCropVariety(e.target.value)}>
                                                    <option value="0"> --Select Crop --</option>
                                                    {cropVarietyOpt}
                                                </Input>
                                            </Col>
                                            <Col sm={3} className="mb-2">
                                                <Label for="state"> State </Label>
                                                <Input id="state" bsSize="sm" type="select" value={state} onChange={get_dist_list}>
                                                    <option value="0"> --Select State--</option>
                                                    {stateOpt}
                                                </Input>
                                            </Col>
                                            <Col sm={3} className="mb-2">
                                                <Label for="district"> District </Label>
                                                <Input id="district" bsSize="sm" type="select" value={district} onChange={get_block_list}>
                                                    <option value="0"> --Select District--</option>
                                                    {districtOpt}
                                                </Input>
                                            </Col>
                                            <Col sm={3} className="mb-2">
                                                <Label for="block"> Block </Label>
                                                <Input id="block" bsSize="sm" type="select" value={block} onChange={(e)=>setBlock(e.target.value)}>
                                                    <option value="0"> --Select Block--</option>
                                                    {blockOpt}
                                                </Input>
                                            </Col>
                                            <Col md={2} className="mb-2">
                                                <Button className='text-white mt-4' color='primary' size="sm" block> Get </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card className='' color="primary" outline>
                                <CardHeader>Farmer List <small className='text-muted'>Cultivation</small></CardHeader>
                                <Col sm={{ offset: 6, size: 3 }} style={{ position: 'absolute', top: '2px', right: '2px' }}>
                                    <InputGroup size="sm">
                                        <InputGroupText> <FaSearch /> </InputGroupText>
                                        <Input type="search" placeholder='Search' />
                                    </InputGroup>
                                </Col>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.sl,
                                                sortable: true,
                                                width: '80px'
                                            },
                                            {
                                                name: 'State',
                                                selector: row => row.state,
                                                sortable: false,
                                            },
                                            {
                                                name: 'District',
                                                selector: row => row.district,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Block',
                                                selector: row => row.block,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Village',
                                                selector: row => row.village,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Farmer Name',
                                                selector: row => row.name,
                                                sortable: false,
                                                width:"20%"
                                            },
                                            {
                                                name: 'Start Date',
                                                selector: row => row.date,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Action',
                                                selector: row => row.action,
                                                sortable: false,
                                            },
                                        ]}
                                        data={farmerTable}
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </CardBody>
                            </Card>
                            {/*<div className="text-end mt-3">
                                <Button className='text-white' color='primary' size="sm" onClick={back_handler}> Back </Button>
                                    </div>*/}
                        </Col>
                    }

                    { mode === '3' &&
                        <Col xs={12}>
                            <TraceabilityDetail data={traceDetail} />
                            <div className="text-end mt-3">
                                <Button className='text-white' color='primary' size="sm" onClick={()=>setMode('1')}> Back </Button>
                            </div>
                        </Col>
                    }


                </Row>

            </Page>
            <Footer />

        </>
    )

}

export default Traceability;