import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';
import { mainurl } from '../../Mainurl';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, InputGroup, InputGroupText, Button, } from 'reactstrap';
import { FaEye, FaSearch, } from 'react-icons/fa';

const LivestockAlert = () => {
    const [mode, setMode] = useState("1");
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');

    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');

    const get_livestock_Alert = (e) => {
        e.preventDefault();

        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": sessionStorage.getItem('user_id'), "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Activity/animal_alert',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
        .then(function (response) {
            let list = response.data.map((row, index) => {
                return(
                    {
                       sl: index + 1,
                       farmer_id: row.farmer_id,
                       //f_name: row.f_name,
                       activity_desc: row.activity_desc,
                       animal_type_name: row.animal_type_name,
                       future_activity_date: row.future_activity_date.split(" ")[0].split('-').reverse().join('-'),
                    }
                )
            });

            setTableData(list);
        })
        .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
            //notify();
        });    

        
    }

  


    return (
        <>
            <Header />
            <Page title='Livestock Alert' breadcrumbs='Home / Livestock Alert'>
                <Row className='p-3'>
                    {mode === "1" &&
                        <Col xs={12}>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Form onSubmit={get_livestock_Alert}>
                                        <Row>
                                            <Col md={3} className="mb-2">
                                                <Button className='text-white' color='primary' size="sm" block> Get Livestock Alert </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card className='' color="primary" outline>
                                <CardHeader>Livestock Alert <small className='text-muted'> </small></CardHeader>
                                <Col sm={{ offset: 6, size: 3 }} style={{ position: 'absolute', top: '2px', right: '2px' }}>
                                    <InputGroup size="sm">
                                        <InputGroupText> <FaSearch /> </InputGroupText>
                                        <Input type="search" placeholder='Search' />
                                    </InputGroup>
                                </Col>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.sl,
                                                sortable: true,
                                                width: '80px'
                                            },
                                            {
                                                name: 'Farmer ID',
                                                selector: row => row.farmer_id,
                                                sortable: false,
                                                width: '10%'
                                            },
                                            {
                                                name: 'Animal Type',
                                                selector: row => row.animal_type_name,
                                                sortable: false,
                                                width: '10%'
                                            },
                                            {
                                                name: 'Activity Description',
                                                selector: row => row.activity_desc,
                                                sortable: false,
                                                width: '20%'
                                            },
                                            {
                                                name: 'Activity Future Date',
                                                selector: row => row.future_activity_date,
                                                sortable: false,
                                            },
                                           
                                        ]}
                                        data={tableData}
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    }

                </Row>

            </Page>

            <Footer />

        </>
    )

}

export default LivestockAlert;